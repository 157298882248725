/**
 * Home slider.
 */

const slider = () => {
  /* eslint-disable no-undef */
  $(".slider__slick").slick({
    infinite: true,
    slidesToShow: 4,
    dots: false,
    loop: true,
    autoPlay: true,
    prevArrow: $(".slider__button--previous"),
    nextArrow: $(".slider__button--next"),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });
  /* eslint-enable no-undef */
};

slider();

const forms = () => {
  const buttons = document.querySelector(".form__buttons");

  if (!buttons) {
    return;
  }

  buttons.addEventListener("click", event => {
    const attribute = event.target.getAttribute("data-form");

    if (!attribute) {
      return;
    }

    const forms = document.querySelectorAll(".form__form");

    forms.forEach(form => {
      form.classList.remove("form__form--active");
    });

    const form = document.querySelector(`.${attribute}`);

    form.classList.add("form__form--active");
  });
};

forms();

// eslint-disable-next-line no-undef
const wow = new WOW();

wow.init();
