/**
 * Menu.
 * 
 * Toggle the state of the menu and underlay.
 * 
 * @author Louis
 */

class Menu {
  constructor(elements) {
    this.icon = elements.icon;
    this.navigation = elements.navigation;
    this.underlay = elements.underlay;
    this.page = elements.page;
  }

  /**
   * Toggle display state.
   */

  toggle() {
    this.icon.classList.toggle("is-active");
    this.navigation.classList.toggle("navigation--open");
    this.underlay.classList.toggle("menu__underlay--active");
    this.page.classList.toggle("page--locked");

    // iOS fallback.
    /* eslint-disable no-undef */
    if(this.page.classList.contains('page--locked')) {
      bodyScrollLock.disableBodyScroll(this.navigation);
    } else {
      bodyScrollLock.enableBodyScroll(this.navigation);
    }
    /* eslint-enable no-undef */
  }

  /**
   * Bind events.
   */

  initialise() {
    this.icon.addEventListener("click", () => {
      this.toggle();
    });

    this.underlay.addEventListener("click", () => {
      this.toggle();
    });
  }
}

// Configuration.
const elements = {
  icon: document.querySelector(".hamburger"),
  navigation: document.querySelector(".navigation"),
  underlay: document.querySelector(".menu__underlay"),
  page: document.querySelector(".page")
};

const menu = new Menu(elements);

menu.initialise();
